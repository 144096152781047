<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="New">
        <Change_Vehicle></Change_Vehicle>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Change_Vehicle from "./_tab/table-change-vehicle.vue";
export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Change_Vehicle,
  },
  data() {
    return {
      title: "Change Vehicle",
    };
  },
};
</script>
